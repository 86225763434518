import { useContext, useEffect, useState } from 'react';
import { Toaster } from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DownButton } from '../../../Components/Predict/actionButton/downButton';
import { UpButton } from '../../../Components/Predict/actionButton/upButton';
import PLoader from '../../../Components/Predict/loader/Loader';
import GameContext from '../../../Context/GameContext';
import { useTheme } from '../../../Context/Predict/themeContext';
import { Category } from '../../../Domain/Category/category';
import { CatergoryProvider } from '../../../Domain/Category/categoryProdider';
import { Game } from '../../../Domain/Predict/game';
import { PredictProvider } from '../../../Domain/Predict/predictProdiver';
import LinkIcon from '../../../Resources/Predict/icons/link.svg';
import LinkDarkIcon from '../../../Resources/Predict/icons/link_dark.svg';
import PixieLogo from '../../../Resources/Predict/images/PixieLogo.png';
import style from './predictionsStyle.module.scss';
import { ButtonTelegramAuth } from '../../../Components/Predict/buttons/ButtonTelegramAuth';

export default function PredictionsPage() {
    const { token, userId } = useContext(GameContext);
    const { theme } = useTheme();

    const [games, setGames] = useState<Game[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [gamesIsLoaded, setGamesIsLoaded] = useState<boolean>(false);

    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(-1);
    const [categories, setCategories] = useState<Category[]>([]);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        loadCaregories();
    }, [])

    useEffect(() => {
        loadGames();
    }, [selectedCategoryId])


    async function loadCaregories() {
        setIsLoaded(false);

        const categories = await CatergoryProvider.get(token!);
        const categoriesWithYourSelf = [new Category(-1, "All", "All", "All"), ...categories];

        setCategories(categoriesWithYourSelf);

        setIsLoaded(true);
    }

    async function loadGames() {
        if (selectedCategoryId == null) return;

        setGamesIsLoaded(false);

        const games = await PredictProvider.getGames(selectedCategoryId, token!);
        setGames(games);

        setGamesIsLoaded(true);
    }

    function navigateToGameProcess(game: Game) {
        if ([331, 341].includes(game.id)) return;

        navigate(`/predict/predictions/${game.id}`)
    }

    function translatedName(caregory: Category) {
        if (i18n.language === 'ru') {
            return caregory.name_ru;
        } else if (i18n.language === 'uk') {
            return caregory.name_uk;
        } else {
            return caregory.name_en;
        }
    }

    function formatName(name: string | null) {
        if (name == null) return;

        const a = name.split(' ');
        return a[0];
    }

    if (!isLoaded) return <PLoader />

    return (
        <>
            <ButtonTelegramAuth />
        </>
    )

    return (
        <div className={style.mainPageContainer}>
            <div className={style.header}>
                <div className={style.header_left}>
                    <div className={style.header_left_logo} onClick={() => navigate("/predict/predictions")}>
                        <img src={PixieLogo} alt="PixieLogo" />
                    </div>
                    {/* <div className={style.header_left_search}>
                        <img src={SearchIcon} alt="SearchIcon" />
                        <input type='text' placeholder='Search' />
                    </div> */}
                </div>

                {/* <div className={style.header_createButton} onClick={() => navigate("/predict/predictions/create")}>
                    <img src={PlusLogo} alt="PlusLogo" />
                    <span>{t('Add your own prediction')}</span>
                </div> */}
            </div>

            <div className={style.category_filters}>
                {
                    categories.map(category =>
                        <div key={category.id}
                            onClick={() => setSelectedCategoryId(category.id)}
                            className={`${style.category_filters_category} ${selectedCategoryId == category.id ? style.active : ''}`}
                        >
                            {translatedName(category)}
                        </div>
                    )
                }
            </div>

            <div className={style.gameListContainer}>
                {
                    gamesIsLoaded
                        ? games.map(game =>
                            <div key={game.id} className={style.gameListContainer_gameCard} onClick={() => navigateToGameProcess(game)}>
                                {[331, 341].includes(game.id) && <div className={style.comingSoonContainer}> <span>Coming soon...</span> </div>}
                                <div className={style.gameListContainer_gameCard_info}>
                                    <div className={style.gameListContainer_gameCard_info_left}>
                                        <img src={game.image ? `${process.env.REACT_APP_FILE_STORAGE_URL}/${game.image}` : ""} alt="" />
                                    </div>
                                    <div className={style.gameListContainer_gameCard_info_right}>
                                        <div className={style.gameListContainer_gameCard_info_right_hedaer}>
                                            <span>
                                                Created by {game.creator_telegram_id == -1
                                                    ? <span >System</span>
                                                    : (game.last_name != null || game.first_name != null)
                                                        ? <span >
                                                            {game.first_name != null && `${game.first_name} `}

                                                            {game.last_name != null && game.last_name}
                                                        </span>
                                                        : <span>System</span>
                                                }
                                            </span>
                                            <div>
                                                <a href={`https://t.me/share/url?url=https://t.me/pixie_project_bot?start=predict${game.id}_${userId}&text=${game.description}`}>
                                                    <img src={theme == "dark" ? LinkDarkIcon : LinkIcon} alt="LinkIcon" />
                                                </a>
                                                {/* <img onClick={e => { e.stopPropagation() }} src={theme == "dark" ? CommentDarkIcon : CommentLightIcon} alt="CommentDarkIcon" /> */}

                                                {/* <img onClick={e => { e.stopPropagation() }} src={theme == "dark" ? OutlineStarDarkIcon : OutlineStarIcon} alt="OutlineStarIcon" /> */}
                                            </div>
                                        </div>
                                        <div className={style.gameListContainer_gameCard_info_right_gameName}>
                                            {game.name}
                                        </div>
                                        {
                                            [1, 3].includes(game.id) &&
                                            <div className={style.gameListContainer_gameCard_info_right_gameDescription}>
                                                {game.id == 1
                                                    ? "Short 1-minute long BTC price prediction games with only two outcomes: higher or lower"
                                                    : "Short 5-minute long ETH price prediction games with only two outcomes: higher or lower"
                                                }

                                            </div>
                                        }

                                        {/* <div className={style.gameListContainer_gameCard_info_right_statistics}>
                                    <div>
                                        <img src={theme == 'dark' ? CalendarDarkIcon : CalendarIcon} alt="CalendarIcon" />
                                        <span>{game.created_at.toLocaleDateString()}</span>
                                    </div>
                                </div> */}
                                    </div>
                                </div>

                                {
                                    game.id != 4 &&
                                    <div className={style.gameListContainer_gameCard_action}>
                                        <DownButton title={formatName(game.button_name_down) ?? ""} subTitle={game.coef_down == null ? null : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"} />
                                        <UpButton title={formatName(game.button_name_up) ?? ""} subTitle={game.coef_up == null ? null : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"} />
                                    </div>
                                }

                                {
                                    game.id == 4 &&
                                    <div className={`${style.gameListContainer_gameCard_action} ${style.coef}`}>
                                        <div className={style.gameListContainer_gameCard_action_coef}>
                                            {game.button_name_down}
                                            {(game.coef_down ?? 0) < (game.coef_up ?? 0)
                                                ? <DownButton title={game.coef_down == null ? "0x" : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"} withoutIcon />
                                                : <UpButton title={game.coef_down == null ? "0x" : (+(+game.coef_down.toFixed(2))).toLocaleString('en') + "x"} withoutIcon />
                                            }
                                        </div>
                                        <div className={style.gameListContainer_gameCard_action_coef}>
                                            {game.button_name_up}
                                            {(game.coef_up ?? 0) < (game.coef_down ?? 0)
                                                ? <DownButton title={game.coef_up == null ? "0x" : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"} withoutIcon />
                                                : <UpButton title={game.coef_up == null ? "0x" : (+(+game.coef_up.toFixed(2))).toLocaleString('en') + "x"} withoutIcon />
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        )
                        : <PLoader />
                }
            </div>
            <div><Toaster /></div>
        </div>
    )
}
