import { useEffect, useRef } from 'react'

interface IAuth {
    id: string,
    first_name: string,
    last_name: string,
    username: string,
    photo_url: string,
    auth_date: string,
    hash: string
}

declare global {
    interface Window {
        onTelegramAuth: (user: IAuth) => void
    }
}

export const ButtonTelegramAuth = () => {
    const widgetContainerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const button = document.createElement('script')
        button.async = true
        button.src = 'https://telegram.org/js/telegram-widget.js?22'
        button.setAttribute('data-telegram-login', 'DMHelperr_bot')
        button.setAttribute('data-size', 'large')
        button.setAttribute('data-radius', '20')
        button.setAttribute('data-onauth', 'onTelegramAuth')

        if (widgetContainerRef.current) {
            widgetContainerRef.current.appendChild(button)
        }

        window.onTelegramAuth = function (user) {
            alert(
                'Logged in as ' +
                user.first_name +
                ' ' +
                user.last_name +
                ' (' +
                user.id +
                (user.username ? ', @' + user.username : '') +
                ')'
            )
        }

        return () => {
            if (widgetContainerRef.current) {
                widgetContainerRef.current.removeChild(button)
            }
        }
    }, [])

    return <div ref={widgetContainerRef} id="telegram-widget-container"></div>
}
